// JavaScript Document
jQuery(function ($) {
    /*------------------
      scroll
    --------------------*/
    if (window.innerWidth >= 768) {
        var headerHight = 50;
    } else {
        var headerHight = 80;
    }
    var speed = 400;

    $(document).on("click", 'a[href^="#"]', function () {
        var href = $(this).attr("href");
        var target = $(href === "#" || href === "#" ? "html" : href);
        var position = target.offset().top - headerHight;
        $("html, body").animate({ scrollTop: position }, speed, "swing");
        return false;
    });

    var url = $(location).attr("href");
    if (url.indexOf("?id=") == -1) {
    } else {
        var url_sp = url.split("?id=");
        var hash = "#" + url_sp[url_sp.length - 1];
        var target2 = $(hash);
        if (target2[0]) {
            var position2 = target2.offset().top - headerHight;
            $("html, body").animate({ scrollTop: position2 }, speed, "swing");
        }
    }

    //スクロールアニメ
    $(window).scroll(function () {
        $(".anim").each(function () {
            var imgPos = $(this).offset().top;
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > imgPos - windowHeight + windowHeight / 6) {
                $(this).addClass("is-act");
            }
        });
        $(".anim-list").each(function () {
            var elemPos = $(this).offset().top;
            var scroll = $(window).scrollTop();
            var windowHeight = $(window).height();
            if (scroll > elemPos - windowHeight + windowHeight / 6) {
                var i = 0;
                var $list = $(this).children();
                setInterval(function () {
                    $list.eq(i).addClass("is-act");
                    i++;
                    if (i >= $list.length) i = 0;
                }, 250);
            }
        });
    });

    $("#menu-btn").on("click", function () {
        $(this).toggleClass("open");
        if ($(this).hasClass("open")) {
            $("#g-navi").addClass("open");
            $("body").addClass("fixed");
        } else {
            $("#g-navi").removeClass("open");
            $("body").removeClass("fixed");
        }
    });
    $("#g-navi a").on("click", function () {
        $("#menu-btn").removeClass("open");
        $("#g-navi").removeClass("open");
        $("body").removeClass("fixed");
    });

    const mediaQueryList = window.matchMedia("(min-width: 1000px)");

    const listener = (event) => {
        if (event.matches) {
            $("#menu-btn").removeClass("open");
            $("#g-navi").removeClass("open");
            $("body").removeClass("fixed");
        }
    };
    mediaQueryList.addEventListener("change", listener);
    listener(mediaQueryList);
});

// 同意チェック
document.addEventListener("DOMContentLoaded", function () {
    const agreeCheckbox = document.getElementById("agree");
    const submitBtn = document.getElementById("btn-submit");
    if (!agreeCheckbox) {
        return false;
    }
    agreeCheckbox.addEventListener("click", () => {
        if (agreeCheckbox.checked === true) {
            submitBtn.disabled = false;
        } else {
            submitBtn.disabled = true;
        }
    });
});

// ユーザーエージェント
let ua = navigator.userAgent;
if (ua.indexOf("iPhone") === -1 && ua.indexOf("Android") === -1) {
    jQuery('a[href^="tel:"]')
        .css("cursor", "default")
        .on("click", function (e) {
            e.preventDefault();
        });
}
